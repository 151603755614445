import React, { useState, useEffect } from "react";
import { Avatar, Checkbox, Input, Tooltip, Pagination } from "antd";
import { Button, Dropdown, Loader } from "../../../../components";
import {
  ACCESS_LIST_PAGE_SIZE,
  PROJECT_ROLES,
  PROJECT_ROLES_OPTIONS,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { updateProjectUserRequest } from "../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faAngleUp,
  faAngleDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const UserDetail = ({ data, isOwner, selectUser, isSelected }) => {
  // STATES
  const [selectedRole, setselectedRole] = useState(PROJECT_ROLES_OPTIONS[0]);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.selectedProject);
  // const users = useSelector(({ project }) => project.projectUsers);

  // CONST VALS
  const platformAccess = data?.auth_apps ? data?.auth_apps.platform : true;
  const roleOptions = platformAccess
    ? PROJECT_ROLES_OPTIONS
    : PROJECT_ROLES_OPTIONS.filter(
        (role) => role.value !== PROJECT_ROLES.EDITOR
      );

  // CUSTOM DISPATCH
  const [updateRole] = CustomDispatch(updateProjectUserRequest);

  // HELPERS
  const updateRoleHandler = (role) => {
    setselectedRole(role);
    const payload = {
      resource: "access",
      method: "update",
      details: {
        project_id: projectData.id,
        users: [
          {
            user_id: data?.user_id,
            role: role.value,
          },
        ],
      },
    };
    updateRole({
      payload,
    });
  };

  // HOOKS
  useEffect(() => {
    if (!data) return;
    const role = PROJECT_ROLES_OPTIONS.find((x) => x.value === data?.role);
    setselectedRole(role);
  }, [data]);

  return (
    <div className="shared-list-container" key={data?.user_id ?? data?.email}>
      <div className="check-box">
        {!isOwner && (
          <Checkbox
            checked={isSelected}
            onClick={() => selectUser(data?.user_id)}
          />
        )}
      </div>
      <Avatar className="profile-pic" src={data?.photo_url}>
        {data?.name?.charAt(0) ?? data?.user_id?.charAt(0)}
      </Avatar>
      <button
        className="user-detail user-detail-button"
        onClick={() => selectUser(data?.user_id)}
      >
        <h4>{data?.name ?? data?.user_id}</h4>
        <p>{data?.email ?? "-"}</p>
      </button>
      {data?.role === "owner" ? (
        <Dropdown
          className="role action-button owner-tag"
          selectedVal="Owner"
          selectHandler={() => {}}
        />
      ) : (
        <Dropdown
          className="role action-button"
          selectedVal={selectedRole?.label || "Select Role"}
          selectHandler={(val) => updateRoleHandler(val)}
          options={roleOptions}
          optionBox={({ label }) => label}
        />
      )}
    </div>
  );
};

const SharedList = ({
  selectedUsers,
  setselectedUsers,
  removeUserPreviewHandler,
  isAccessListInProgress,
  accessList = [],
}) => {
  // REDUX DATA

  // CONST VALS
  const collaborators = accessList?.filter((x) => x.role !== "owner");

  // STATES
  const [sortDirectionName, setSortDirectionName] = useState(null);
  const [sortDirectionRole, setSortDirectionRole] = useState(null);
  const [sortedAccessList, setSortedAccessList] = useState(accessList);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(ACCESS_LIST_PAGE_SIZE);

  // HANDLERS
  const selectAllUserHandler = () => {
    if (selectedUsers.length === collaborators.length)
      return setselectedUsers([]);
    setselectedUsers(collaborators.map((user) => user.user_id));
  };

  const selectUserHandler = (userid) => {
    if (selectedUsers.includes(userid))
      return setselectedUsers(selectedUsers.filter((x) => x !== userid));
    setselectedUsers([...selectedUsers, userid]);
  };

  const toggleSortDirectionName = () => {
    const newSortDirection = sortDirectionName === "asc" ? "desc" : "asc";
    setSortDirectionName(newSortDirection);
  };

  const toggleSortDirectionRole = () => {
    const newSortDirection = sortDirectionRole === "asc" ? "desc" : "asc";
    setSortDirectionRole(newSortDirection);
  };

  const onPaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
    }
    if (page !== currentPageNumber) {
      setCurrentPageNumber(page);
    }
  };
  const onShowSizeChange = (current, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
    }
  };

  useEffect(() => {
    let filteredUsers = [...accessList];

    // Filter users by search query (search by user_id, name, email)
    if (searchQuery) {
      filteredUsers = filteredUsers.filter((user) => {
        // Ensure name and email are valid before calling toLowerCase()
        const name = user.name ? user.name.toLowerCase() : "";
        const email = user.email ? user.email.toLowerCase() : "";

        return (
          user.user_id.toString().includes(searchQuery) ||
          name.includes(searchQuery.toLowerCase()) ||
          email.includes(searchQuery.toLowerCase())
        );
      });
    }

    // Filter owners
    const owners = filteredUsers.filter((user) => user.role === "owner");
    let otherUsers = filteredUsers.filter((user) => user.role !== "owner");

    // Sort others by name
    if (sortDirectionName) {
      otherUsers.sort((a, b) => {
        // Handle missing name by treating it as an empty string and push to the bottom
        const nameA = a.name ? a.name : ""; // Treat missing names as empty string
        const nameB = b.name ? b.name : ""; // Treat missing names as empty string

        return sortDirectionName === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    }

    // Sort others by role
    if (sortDirectionRole) {
      otherUsers.sort((a, b) =>
        sortDirectionRole === "asc"
          ? a.role.localeCompare(b.role)
          : b.role.localeCompare(a.role),
      );
    }

    setSortedAccessList([...owners, ...otherUsers]);
  }, [
    searchQuery,
    sortDirectionName,
    sortDirectionRole,
    accessList,
    currentPageNumber,
    currentPageSize,
  ]);

  //Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (currentPageNumber !== 1) {
      setCurrentPageNumber(1);
    }
  };

  return (
    <div className="shared-list customize-form">
      <div className="top-box">
        <div className="search-bar-container">
          <Input
            prefix={<FontAwesomeIcon className="thumb" icon={faSearch} />}
            type="text"
            placeholder="Search users here..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        {/* Conditionally render the "Remove selected" button */}
        {selectedUsers.length > 0 && (
          <Button
            invertedTheme
            className="manage-btn"
            boldText
            type="button"
            onClick={removeUserPreviewHandler}
          >
            Remove selected
          </Button>
        )}
      </div>

      {/* Check box and Sorting by - name of user, role of user*/}
      <div className="user-list-container">
        <div className="shared-list-header">
          {/* Check box to select users */}
          <div className="check-box header">
            {collaborators.length > 0 && (
              <Checkbox
                checked={selectedUsers.length === collaborators.length}
                onChange={selectAllUserHandler}
              >
                {/*Select all*/}
              </Checkbox>
            )}
          </div>

          {/* Sorting caret for names */}
          <div
            className="user-detail header"
            onClick={toggleSortDirectionName}
            tabIndex="0"
            role="button"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
                toggleSortDirectionName();
              }
            }}
          >
            {/* Tooltip for sorting by User */}
            <Tooltip
              title={
                sortDirectionName === "asc"
                  ? "Click to Sort Descending"
                  : "Click to Sort Ascending"
              }
            >
              <span>
                User{" "}
                <FontAwesomeIcon
                  icon={sortDirectionName === "asc" ? faAngleUp : faAngleDown}
                />
              </span>
            </Tooltip>
          </div>

          {/* Sorting caret for roles */}
          <div
            className="role header"
            onClick={toggleSortDirectionRole}
            tabIndex="0"
            role="button"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
                toggleSortDirectionRole();
              }
            }}
          >
            {" "}
            {/* Tooltip for sorting by Role */}
            <Tooltip
              title={
                sortDirectionRole === "asc"
                  ? "Click to Sort Descending"
                  : "Click to Sort Ascending"
              }
            >
              <span>
                Role{" "}
                <FontAwesomeIcon
                  icon={sortDirectionRole === "asc" ? faAngleUp : faAngleDown}
                />
              </span>
            </Tooltip>
          </div>
        </div>

        {isAccessListInProgress && accessList.length === 0 ? (
          <Loader height={300} />
        ) : (
          <>
            {(sortedAccessList ?? [])
              .slice(
                (currentPageNumber - 1) * currentPageSize,
                currentPageNumber * currentPageSize,
              )
              .map((user) => (
                <UserDetail
                  data={user}
                  key={user.user_id}
                  isOwner={user.role === "owner"}
                  selectUser={selectUserHandler}
                  isSelected={selectedUsers.includes(user.user_id)}
                />
              ))}
          </>
        )}
      </div>
      {Array.isArray(sortedAccessList) && (
        <Pagination
          className="user-pagination"
          total={sortedAccessList.length}
          currentPageNumber={currentPageNumber}
          hideOnSinglePage={true}
          defaultPageSize={ACCESS_LIST_PAGE_SIZE}
          pageSize={currentPageSize}
          responsive
          showSizeChanger={false}
          onChange={onPaginationChange}
          onShowSizeChange={onShowSizeChange}
          itemRender={(page, type, originalElement) => {
            if (type === "next") {
              return (
                <span className="pagination-action-box">
                  {!!isAccessListInProgress && accessList.length > 0 && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                  {originalElement}
                </span>
              );
            }
            return originalElement;
          }}
        />
      )}
    </div>
  );
};

export default SharedList;
