import { useState, useEffect } from 'react';
import { CustomDispatch } from '../helpers';
import { getProjectUsersRequest } from '../redux/slicers/project';

const useGetProjectAccessList = (projectId) => {
  const [currentDataKey, setCurrentDataKey] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [downloadedData, setDownloadedData] = useState(undefined);
  const [getProjectUsers] = CustomDispatch(getProjectUsersRequest);

  const dataKey = `${projectId}`;

  const reload = () => {
    setDownloadedData(undefined);
    setMoreData(false);
  };

  // if the user changed params passed in then handle the change.
  if (currentDataKey && currentDataKey !== dataKey) {
    // Clear down possible incomplete data.
    reload();
    setCurrentDataKey(dataKey);
  }

  useEffect(() => {
    // Handle if no project set.
    if (!projectId) return;

    // If we have downloaded data and nothing more to retrieve then we're done
    if (downloadedData && !moreData) return;

    const payload = {
      resource: 'access',
      method: 'describe',
      details: {
        project_id: projectId
      },
      // 50 can take a while, 30 seems to come back quicly and provides a responsive experience.
      item_limit: 30
    };
    if (moreData) payload['next_page'] = moreData;

    getProjectUsers({
      payload,
      success: (data) => {
        setMoreData(data?.data?.next_page);
        if (!data?.data?.next_page) {
          setIsComplete(true);
        }
        if (downloadedData) {
          setDownloadedData((prev) => [
            ...(prev ?? []),
            ...(data?.data?.users ?? [])
          ]);
        } else {
          setDownloadedData([...(data?.data?.users ?? [])]);
        }
      }
    });
  }, [projectId, moreData, downloadedData, getProjectUsers]);

  return { isComplete, isInProgress: !!moreData, data: downloadedData, reload };
};

export default useGetProjectAccessList;
