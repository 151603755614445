import React, { useState, useCallback, useRef } from 'react';
import { Image, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRepeat,
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
  faCircleCheck,
  faCommentDots,
  faFlag as faFlagSolid,
} from '@fortawesome/free-solid-svg-icons';
import ShareIcon from '../../../../../components/icons/ShareIcon';
import {
  faCopy,
  faFlag,
  faThumbsDown,
  faThumbsUp,
  faFile,
  faFileAudio,
} from '@fortawesome/free-regular-svg-icons';
import { PulseLoader } from 'react-spinners';
import { Markdown } from '../../../../../components';
import clsx from 'clsx';
import FeedbackModal from '../feedback';
import { CustomDispatch } from '../../../../../helpers/custom-dispatch';
import {
  deleteFeedbackRequest,
  sendFeedbackRequest,
} from '../../../../../redux/slicers/chat';
import { isValidURL, toastAlert } from '../../../../../utils';
import { ALERT_TYPES } from '../../../../../constants';
// import PromptRating from "../rating-modal";
import './styles.scss';

const MessageBox = React.forwardRef(
  (
    {
      data,
      isLoading,
      reGenerateMessage,
      previousMessage,
      selectedProject,
      scrollToBottom = () => {},
      showTextHighlight = [],
      setShowTextHighlight = undefined,
    },
    ref
  ) => {
    // STATES
    const [isCopied, setIsCopied] = useState(false);
    const [isShared, setIsShared] = useState(false);
    const [reportPreview, setReportPreview] = useState(false);
    // const [ratingPreview, setRatingPreview] = useState(false);
    // const [likeRating, setLikeRating] = useState(false);

    // CONST VALS
    const isLiked = data?.feedback?.reaction === 'like';
    const isDisliked = data?.feedback?.reaction === 'dislike';
    const isReported = data?.feedback?.report;
    const messageBoxTitle =
      selectedProject?.botName ?? data?.modelLabel ?? data?.model ?? 'GPT';
    const textToShare = previousMessage
      ? `Prompt: ${previousMessage.message.trim()}\nResponse: ${data.message.trim()}`
      : undefined;
    const markdownClasses = clsx(
      'data-message'
      // Commented out per request 3/5/2025 - Dylan
      // Array.isArray(showTextHighlight) && showTextHighlight.includes(data.id)
      //   ? 'data-message-highlight'
      //   : ''
    );

    // CUSTOM DISPATCH
    const [sendFeedback] = CustomDispatch(sendFeedbackRequest);
    const [deleteFeedback] = CustomDispatch(deleteFeedbackRequest);

    // HELPERS
    const submitFeedbackHelper = ({
      reaction = data?.feedback?.reaction,
      report = data?.feedback?.report,
      reason = data?.feedback?.reason,
      rating = data?.feedback?.rating,
    }) => {
      const isDeleteReq = !reaction && !report && !reason && !rating;
      const payload = {
        resource: 'feedback',
        method: isDeleteReq ? 'delete' : 'put',
        details: { query_id: data.id },
      };
      if (isDeleteReq) {
        deleteFeedback({ payload });
        return;
      }
      payload.details['feedback'] = {
        reaction,
        report,
        reason,
        rating,
      };
      sendFeedback({ payload });
    };

    // HANDLERS
    const handleReport = () => {
      setReportPreview(!reportPreview);
    };
    const handleLike = () => {
      submitFeedbackHelper({ reaction: isLiked ? null : 'like' });
      // if (isLiked) {
      //   setRatingPreview(true);
      //   setLikeRating(true);
      // }
    };
    const handleDislike = () => {
      submitFeedbackHelper({ reaction: isDisliked ? null : 'dislike' });
      // if (isDisliked) {
      //   setRatingPreview(true);
      //   setLikeRating(false);
      // }
    };
    const handleCopy = () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
    const handleShareClick = async () => {
      try {
        if (navigator?.share) {
          const sharedData = {
            title: `Project: ${messageBoxTitle}`,
            text: textToShare,
          };
          await navigator.share(sharedData);
          setIsShared(true);
        } else {
          toastAlert(
            'Your browser does not support sharing.',
            ALERT_TYPES.ERROR
          );
        }
      } catch (e) {
        console.log(`Error while sharing.`, e);
      } finally {
        setTimeout(() => {
          setIsShared(false);
        }, 2000);
      }
    };

    const handleCopyMouseOver = () => {
      if (!showTextHighlight.includes(data.id)) {
        setShowTextHighlight([data.id, previousMessage?.id]);
      }
    };
    const handleCopyMouseOut = () => {
      if (showTextHighlight.length !== 0) {
        setShowTextHighlight([]);
      }
    };

    const handleShareMouseOver = () => {
      if (!showTextHighlight.includes(data.id)) {
        setShowTextHighlight([data.id, previousMessage?.id]);
      }
    };
    const handleShareMouseOut = () => {
      if (showTextHighlight.length !== 0) {
        setShowTextHighlight([]);
      }
    };

    // CONST VALS
    const sources = data?.sources?.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.source_name === obj.source_name)
    );
    const sourcesPreview = sources?.length >= 1;
    const messageOptions = [
      {
        icon: isLiked ? faThumbsUpSolid : faThumbsUp,
        title: isLiked ? 'Unlike' : 'Like',
        onClick: handleLike,
        isActive: isLiked,
      },
      {
        icon: isDisliked ? faThumbsDownSolid : faThumbsDown,
        title: isDisliked ? 'Undislike' : 'Dislike',
        onClick: handleDislike,
        isActive: isDisliked,
      },
      {
        icon: isReported ? faFlagSolid : faFlag,
        title: `Report${isReported ? 'ed' : ''}`,
        onClick: handleReport,
        isActive: isReported,
      },
    ];
    if (textToShare) {
      messageOptions.push({
        title: isCopied ? 'Copied' : 'Copy',
        child: (
          <CopyToClipboard text={textToShare} onCopy={handleCopy}>
            <button
              className={isCopied ? 'active' : ''}
              onMouseOver={handleCopyMouseOver}
              onFocus={handleCopyMouseOver}
              onMouseOut={handleCopyMouseOut}
              onBlur={handleCopyMouseOut}
            >
              <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
            </button>
          </CopyToClipboard>
        ),
      });
      messageOptions.push({
        title: isShared ? 'Shared' : 'Share',
        child: (
          <button
            className={isShared ? 'active' : ''}
            onMouseOver={handleShareMouseOver}
            onFocus={handleShareMouseOver}
            onMouseOut={handleShareMouseOut}
            onBlur={handleShareMouseOut}
            onClick={handleShareClick}
          >
            {isShared ? (
              <FontAwesomeIcon icon={faCircleCheck} />
            ) : (
              <ShareIcon />
            )}
          </button>
        ),
      });
    }

    // CUSTOM COMPONENTS
    const UserMessage = (
      <div
        className='message-content'
        ref={ref}
        key={`${data.id}-${markdownClasses}`}
      >
        <Markdown className={markdownClasses}>{data.message}</Markdown>
        {data?.images?.length > 0 && (
          <div className='images-wrapper'>
            <div className='image-slides'>
              {data?.images.map((item, index) => {
                const isImage = item.type === 'image';
                const isAudio = item.type === 'audio';
                return (
                  <div
                    key={index}
                    className={clsx('media-box', isImage ? 'image' : 'file')}
                  >
                    {!!isImage && (
                      <Image preview src={item.data} alt={item.name} />
                    )}
                    {!isImage && (
                      <>
                        <div className='thumb'>
                          <FontAwesomeIcon
                            icon={isAudio ? faFileAudio : faFile}
                          />
                        </div>
                        <div className='detail'>
                          <h4>{item.name}</h4>
                          <p>{isAudio ? 'Audio' : 'Document'}</p>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Tooltip title={isLoading ? '' : 'Regenerate'}>
          <FontAwesomeIcon
            icon={faRepeat}
            onClick={() => {
              !isLoading &&
                reGenerateMessage(data.message, data?.images, data?.queryId);
            }}
            className='regenerate'
            style={{
              opacity: isLoading ? 0.5 : 1,
              cursor: isLoading ? 'auto' : 'pointer',
            }}
          />
        </Tooltip>
      </div>
    );

    const PromptMessage = (
      <div ref={ref} key={`${data.id}-${markdownClasses}`}>
        <div className='top-box'>
          <div className='thumbnail'>
            {/* {promptThumb} */}
            <span className='title'>{messageBoxTitle}</span>
          </div>
          {!!data.isSpeech && (
            <div className='speech-box' data-testid='speech-box'>
              <FontAwesomeIcon icon={faCommentDots} />
              <span>Speech</span>
            </div>
          )}
        </div>
        {data.isLoading ? (
          <div className='message-loader' data-testid='message-loader'>
            <PulseLoader size={8} />
          </div>
        ) : (
          <>
            <div className={`message-content ${data.isError ? 'error' : ''}`}>
              <Markdown className={markdownClasses}>{data.message}</Markdown>
            </div>
            {!!data.isError && (
              <button
                className='regenerate-btn'
                onClick={() =>
                  reGenerateMessage(
                    previousMessage.message,
                    previousMessage?.images,
                    previousMessage?.queryId
                  )
                }
              >
                Regenerate
              </button>
            )}

            {!!data?.isCompleted && (
              <div className={clsx('bottom-box', sourcesPreview && 'sourced')}>
                {!!sourcesPreview && (
                  <ul className='sources'>
                    <li className='title'>Sources:</li>
                    {sources.map((item, index) => {
                      const isLink = isValidURL(item?.source_name);
                      const pageNumer = item?.page_number
                        ? `- Page ${item?.page_number}`
                        : '';
                      const isLongSource = item?.source_name.length > 30;
                      const source = `${
                        isLongSource
                          ? item?.source_name.slice(0, 30) + '...'
                          : item?.source_name
                      } ${pageNumer}`;
                      const sourceData = () => (
                        <>
                          <span className='number'>{index + 1}</span>
                          {isLongSource ? (
                            <Tooltip title={item?.source_name}>
                              <span className='source'>{source}</span>
                            </Tooltip>
                          ) : (
                            <span className='source'>{source}</span>
                          )}
                        </>
                      );
                      return (
                        <li key={index} className='source-box'>
                          {isLink ? (
                            <a
                              href={item?.source_name}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {sourceData()}
                            </a>
                          ) : (
                            sourceData()
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="action-box">
                  {messageOptions.map((option) => (
                    <Tooltip title={option.title} key={option.title}>
                      {option.child ? (
                        option.child
                      ) : (
                        <button
                          onClick={option.onClick}
                          className={option.isActive ? 'active' : ''}
                          key={option.title}
                        >
                          <FontAwesomeIcon icon={option.icon} />
                        </button>
                      )}
                    </Tooltip>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );

    // HOOKS

    return (
      <>
        <div
          className={`message-box ${data.isPrompt ? 'response' : 'request'}`}
        >
          {data.isPrompt ? PromptMessage : UserMessage}
        </div>
        <FeedbackModal
          isReported={isReported}
          preview={reportPreview}
          data={data?.feedback}
          previewHandler={handleReport}
          submitHandler={submitFeedbackHelper}
        />
        {/* <PromptRating
          preview={ratingPreview}
          likePreview={likeRating}
          previewHandler={() => setRatingPreview(false)}
        /> */}
      </>
    );
  }
);

MessageBox.displayName = 'MessageBox';

export default MessageBox;
