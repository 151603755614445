import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ThankyouModal } from "../../../../components";
import { Checkbox, Form, Input, Rate } from "antd";
import {
  convertObjectToFormData,
  inputFieldRule,
  toastAlert,
  validateImage,
} from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../../../constants";
import {
  submitFeedbackRequest,
  uploadMediaRequest,
} from "../../../../redux/slicers/general";
import { CustomDispatch } from "../../../../helpers";
import "./styles.scss";

const Feedback = ({ preview, previewHandler }) => {
  // STATES
  const [feedbackError, setFeedbackError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [thankyouPreview, setThankyouPreview] = useState(false);

  // CUSTOM DISPATCH
  const [submitFeedback] = CustomDispatch(submitFeedbackRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);

  // HELPERS
  const uploadMediaHelper = (totalFiles, data, files) => {
    try {
      setTotalFiles(totalFiles.length);
      data.forEach((filedata, index) => {
        const object = {
          ...filedata,
          file: files[index],
        };
        delete object.name;
        delete object.url;
        const payload = convertObjectToFormData(object);
        uploadMedia({
          url: filedata.url,
          payload,
          success: () => setUploadedFiles((uploadedFiles) => uploadedFiles + 1),
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  // CONST VALS
  const [form] = Form.useForm();
  const uploadFileInput = useRef(null);
  const statements = [
    {
      category: "nps_score",
      statement: "I would recommend MyAI Builder to someone else at ASU",
    },
    {
      category: "achieved_goals",
      statement: "MyAI Builder helped me achieve my goals",
    },
    {
      category: "ease_of_use",
      statement: "MyAI Builder was easy to use",
    },
    {
      category: "support_quality",
      statement:
        "The support and assistance provided for MyAI Builder were helpful",
    },
    {
      category: "features",
      statement: "MyAI Builder had all the features I needed",
    },
    {
      category: "csat_overall_experience",
      statement: "I am satisfied with my overall experience using MyAI Builder",
    },
    {
      category: "continue_use",
      statement: "I plan to continue using MyAI Builder",
    },
  ];

  // HANDLERS
  const closeHandler = () => {
    setLoading(false);
    form.resetFields();
    setSelectedFile([]);
    setTotalFiles(0);
    setUploadedFiles(0);
    previewHandler();
  };

  const provideAnotherFeedback = () => {
    setThankyouPreview(false);
    closeHandler();
  };

  const removeFileHandler = (file) => {
    setSelectedFile((prevFiles) =>
      prevFiles.filter((item) => item.name !== file.name)
    );
  };

  const selectFileHandler = (files) => {
    const promises = Array.from(files).map((file) => {
      const fileName = file.name;
      const isValid = validateImage({ file });
      if (!isValid) return null;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({
            type: "image",
            data: e.target.result,
            file: file,
            name: fileName,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((results) => {
        const validFiles = results.filter(Boolean);
        if (validFiles.length > 0) {
          setSelectedFile((prevFiles) => [...prevFiles, ...validFiles]);
        }
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const uploadFileHandler = (e) => {
    const files = e.target.files;
    let uploadedFiles = [];
    if (!files) return;

    const filesArray = Array.from(files);
    const totalFiles = selectedFile.length + filesArray.length;

    if (totalFiles > 5)
      toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

    for (const file of filesArray) {
      const allfiles = [...selectedFile, ...uploadedFiles];
      const isExist = allfiles.some((item) => item.name === file.name);
      if (isExist) {
        toastAlert(
          `${file.name} is already uploaded. Please upload a different file.`,
          ALERT_TYPES.ERROR
        );
      } else {
        uploadedFiles.push(file);
        if (uploadedFiles.length >= 5) break;
      }
    }

    if (uploadedFiles.length > 0) selectFileHandler(uploadedFiles);
    if (uploadFileInput.current) uploadFileInput.current.value = "";
  };

  const submitHandler = (values) => {
    const filledStatements = values.statements.filter((x) => x.rating > 0);
    if (filledStatements.length < 1) {
      setFeedbackError(TOAST_MESSAGES.STATEMENT_REQUIRED);
      return;
    }
    setLoading(true);
    const payload = {
      resource: "form",
      method: "put",
      details: {
        feedback_version: 1,
        project_id: null,
        improvement_suggestion: values.improvement_suggestion,
        further_contact: values.contact ?? false,
        feedback: values.statements.filter((x) => x.rating > 0),
      },
    };
    if (selectedFile.length > 0) {
      const files = selectedFile.map((file) => {
        return {
          source_name: file.name,
        };
      });
      payload.details.uploaded_photo = files;
    }
    submitFeedback({
      payload,
      success(data) {
        if (selectedFile.length > 0) {
          uploadMediaHelper(
            selectedFile,
            data.files,
            selectedFile.map((file) => file.file)
          );
          return;
        }
        closeHandler();
        setThankyouPreview(true);
      },
      error: () => setLoading(false),
    });
  };

  // HOOKS
  useEffect(() => {
    if (uploadedFiles >= 1 && uploadedFiles >= totalFiles) {
      setThankyouPreview(true);
      closeHandler();
    }
  }, [uploadedFiles, totalFiles]);

  return (
    <>
      <Modal
        width={850}
        open={preview}
        openHandler={closeHandler}
        className="feedback-modal customize-form"
      >
        <div className="top-header">
          <h2 className="title">Feedback form</h2>
          <p className="description">
            Thank you for helping us improve. Your name will be linked to your
            feedback for follow-up, but when shared with the ASU community or
            publicly, your responses will remain anonymous.
          </p>
        </div>
        <Form
          form={form}
          onFinish={submitHandler}
          initialValues={{
            statements: statements.map((data) => ({
              statement: data.statement,
              category: data.category,
              rating: 0,
            })),
          }}
          onFieldsChange={() => setFeedbackError(null)}
        >
          <h4 className="sub-title">
            Please indicate the extent to which you agree to the following
            statements on a scale from 1 to 5:
            <br />
            1 = Strong Disagree
            <br />5 = Strongly Agree
          </h4>
          <div className="statements">
            <Form.List name="statements">
              {(fields) =>
                fields.map(({ key }) => {
                  return (
                    <div
                      key={statements[key].statement}
                      className="statement-item"
                    >
                      <label>{statements[key].statement}</label>
                      <Form.Item name={[key, "rating"]}>
                        <Rate />
                      </Form.Item>
                    </div>
                  );
                })
              }
            </Form.List>
            {feedbackError && <p className="error-message">{feedbackError}</p>}
          </div>
          <label htmlFor="description" className="field-label">
            What could we improve to make your experience better?
          </label>
          <Form.Item
            name="improvement_suggestion"
            rules={inputFieldRule({
              name: "improvementSuggestion",
              isRequired: false,
              requiredMessage: "Invaid suggestion",
              isMax: true,
              max: 500,
            })}
          >
            <Input.TextArea id="improvementSuggestion" rows={5} />
          </Form.Item>
          {/* <Form.Item name="contact" valuePropName="checked">
            <Checkbox className="get-updates">
              I would like to be contacted about this problem
            </Checkbox>
          </Form.Item> */}
          <div className="upload-photo">
            <span className="thumb">
              <FontAwesomeIcon icon={faCameraAlt} />
            </span>
            <button className="uploader">
              <input
                type="file"
                multiple
                ref={uploadFileInput}
                onChange={uploadFileHandler}
              />
              Upload photo (Optional)
            </button>
          </div>
          {selectedFile.length > 0 && (
            <div className="images-box">
              <div className="selected-images">
                {selectedFile.map((item, index) => (
                  <div className="image-item" key={index}>
                    <img src={item.data} alt={item.name} />
                    <button
                      className="remove-btn"
                      onClick={() => removeFileHandler(item)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="action-box">
            <Button type="button" onClick={closeHandler}>
              Cancel
            </Button>
            <Button boldText invertedTheme isLoading={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <ThankyouModal
        preview={thankyouPreview}
        feedbackHandler={provideAnotherFeedback}
        previewHandler={() => setThankyouPreview(false)}
      />
    </>
  );
};

export default Feedback;
