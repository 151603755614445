import { useState, useEffect } from 'react';
import { CustomDispatch } from '../helpers';
import { getChatsRequest } from '../redux/slicers/chat';

const useGetChatsList = (projectId) => {
  const [currentDataKey, setCurrentDataKey] = useState(undefined);
  const [isComplete, setIsComplete] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [downloadedData, setDownloadedData] = useState(undefined);
  const [getChats] = CustomDispatch(getChatsRequest);

  const dataKey = `${projectId}`;

  // if the user changed params passed in then handle the change.
  if (currentDataKey && currentDataKey !== dataKey) {
    setCurrentDataKey(dataKey);
    // Clear down possible incomplete data.
    setDownloadedData(undefined);
    setMoreData(false);
    setIsComplete(false);
  }

  useEffect(() => {
    // Handle if no chats have been requested.
    if (!projectId) return;

    // If we have downloaded data and nothing more to retrieve then we're done
    if (downloadedData && !moreData) return;

    const payload = {
      method: 'list',
      details: {
        app_id: projectId,
        archive: false
      },
      item_limit: 50
    };
    if (moreData) payload['next_page'] = moreData;

    getChats({
      payload,
      success: (data) => {
        // This routine gets called twice - we need the one without the next_page set
        // As that has category data
        if (Object.hasOwn(data, 'next_page')) {
          setMoreData(data?.next_page);
          if (!data?.next_page) {
            setIsComplete(true);
          }
        } else {
          if (downloadedData) {
            setDownloadedData((prev) => [...(prev ?? []), ...data]);
          } else {
            setDownloadedData([...data]);
          }
        }
      }
    });
  }, [
    dataKey,
    downloadedData,
    getChats,
    moreData,
    projectId,
    setDownloadedData
  ]);

  return { isComplete, isInProgress: !!moreData, data: downloadedData };
};

export default useGetChatsList;
