import React, { useState } from "react";
import { Avatar, Form, Input } from "antd";
import { CustomDispatch } from "../../../../helpers";
import { getUserDetailsRequest } from "../../../../redux/slicers/user";
import { Button, Dropdown } from "../../../../components";
import { getUserAsurite, toastAlert } from "../../../../utils";
import {
  ALERT_TYPES,
  PROJECT_ROLES_OPTIONS,
  PROJECT_ROLES,
  ASU_SIGNUP_URL,
  TOAST_MESSAGES,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleCheck,
  faPaperclip,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";
import {
  addProjectUserRequest,
  editProjectRequest,
} from "../../../../redux/slicers/project";

const CopyButton = ({ link, text }) => {
  // STATES
  const [isCopied, setCopied] = useState(false);

  // HANDLERS
  const copyHandler = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <CopyToClipboard text={link} onCopy={copyHandler}>
      <Button boldText type="button" className="back-btn">
        <FontAwesomeIcon icon={isCopied ? faCircleCheck : faPaperclip} />
        {text}
      </Button>
    </CopyToClipboard>
  );
};

const AccessDetail = ({ reloadAccessList, accessList, newUsers, setNewUsers }) => {
  // STATES

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // CUSTOM DISPATCH
  const [editProject, editLoader] = CustomDispatch(editProjectRequest);
  const [getUserDetail, userLoader] = CustomDispatch(getUserDetailsRequest);
  const [addUser, addLoader] = CustomDispatch(addProjectUserRequest);

  // CONST VALS
  const [form] = Form.useForm();
  const { href } = window.location;
  const userAsurite = getUserAsurite();
  const disableInvite = newUsers.some(
    (x) => x.role === PROJECT_ROLES.EDITOR && !x.platform_access
  );
  const updateInterface =
    !data?.webInterface &&
    newUsers?.some((x) => x.role === PROJECT_ROLES.VIEWER);

  // HELPERS
  const editProjectHelper = (successHandler) => {
    const payload = {
      resource: "project",
      method: "update",
      details: {
        project_id: data.id,
        public_interface: true,
      },
    };
    editProject({ payload, hideToast: true, success: successHandler });
  };

  const getUserDetailHelper = async (asurites) => {
    const newusers = asurites.replace(/\s*,\s*/g, ",");

    const userslist = newusers
      .split(",")
      .map((user) => user.replace(/@asu\.edu$/, "").trim())
      .filter((user) => user.length > 0);

    if (userslist.length === 0) {
      toastAlert("Please enter a valid ASURITE ID", ALERT_TYPES.ERROR);
      return;
    }

    const updatedList = userslist.filter((userVal) => {
      const user = userVal.toLowerCase().trim();
      if (user === userAsurite) {
        toastAlert("You can't add yourself", ALERT_TYPES.ERROR);
        return false;
      }
      if (accessList.some((x) => x.user_id === user)) {
        toastAlert(
          `User with ASURITE ${user} already exists`,
          ALERT_TYPES.ERROR
        );
        return false;
      }
      return true;
    });
    if (updatedList.length <= 0) return;
    const payload = {
      resource: "user",
      method: "describe",
      details: {
        users: updatedList,
      },
    };
    getUserDetail({
      payload,
      success(data) {
        updatedList.forEach((user) => {
          if (!data?.some((x) => x.id === user)) {
            toastAlert(
              `User with ASURITE ${user} not found`,
              ALERT_TYPES.ERROR
            );
          }
        });
        if (data?.length === 0) return;
        form.resetFields();
        selectUserHandler(data);
      },
    });
  };

  const inviteUserHandler = ({ viewer }) => {
    getUserDetailHelper(viewer);
  };

  // HANDLERS
  const selectUserHandler = (newUsers) => {
    setNewUsers((prevUsers) => {
      const newUniqueUsers = newUsers.filter(
        (newUser) => !prevUsers.some((user) => user.user_id === newUser.id)
      );
      return [
        ...prevUsers,
        ...newUniqueUsers.map((user) => ({
          thumb: user.photo_url,
          user_id: user.id,
          role: PROJECT_ROLES_OPTIONS[0].value,
          platform_access: user?.auth_apps ? user?.auth_apps.platform : true,
          name: user.name,
          email: user.email,
        })),
      ];
    });
  };

  const selectUserRole = (role, id) => {
    let users = [...newUsers];
    const index = users.findIndex((user) => user.user_id === id);
    users[index].role = role;
    setNewUsers(users);
  };

  const removeNewUser = (id) => {
    setNewUsers(newUsers.filter((user) => user.user_id !== id));
  };

  const addUserHandler = () => {
    const users = newUsers.map((user) => {
      return {
        user_id: user?.user_id,
        role: user?.role,
      };
    });
    const payload = {
      resource: "access",
      method: "add",
      details: {
        project_id: data?.id,
        users,
      },
    };
    addUser({
      payload,
      success: () => {
        setNewUsers([]);
        reloadAccessList();
      },
    });
  };

  const inviteHandler = () => {
    if(disableInvite){
      toastAlert(TOAST_MESSAGES.UN_REGISTERED_USER_ERROR, ALERT_TYPES.ERROR);
      return;
    }
    if (updateInterface) {
      editProjectHelper(addUserHandler);
      return;
    }
    addUserHandler();
  };

  return (
    <>
      <Form form={form} className="invite-box" onFinish={inviteUserHandler}>
        <Form.Item
          name="viewer"
          rules={[
            {
              required: true,
              message: "Viewer cannot be empty",
            },
            () => ({
              validator(_, value) {
                if (!value) return Promise.resolve();
                if (value === userAsurite)
                  return Promise.reject(new Error("You can't add yourself"));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Add others using ASURITE (comma separated)" />
        </Form.Item>
        <Button loaderColor="#000" isLoading={userLoader}>
          <FontAwesomeIcon icon={faPlus} /> Add
        </Button>
      </Form>
      <div
        className={clsx(
          "add-users-list",
          newUsers.length > 2 && "scrollbars"
        )}
      >
        {newUsers?.map((currentNewUser) => {
          const userRole = PROJECT_ROLES_OPTIONS.find(
            (role) => role.value === currentNewUser?.role
          );
          const platformAccess =
            userRole?.value === PROJECT_ROLES.EDITOR
              ? currentNewUser?.platform_access
              : true;
          return (
            <div
              className={clsx("data-row", !platformAccess && "expanded")}
              key={currentNewUser?.user_id}
            >
              <Avatar className="profile-pic" src={currentNewUser?.thumb}>
                {currentNewUser?.name?.charAt(0) ??
                  currentNewUser?.user_id?.charAt(0)}
              </Avatar>
              <div className="user-detail">
                <h4>{currentNewUser?.name ?? currentNewUser?.user_id}</h4>
                <p>{currentNewUser?.email ?? "-"}</p>
              </div>
              <div className="role">
                <Dropdown
                  selectedVal={userRole?.label}
                  selectHandler={({ value }) =>
                    selectUserRole(value, currentNewUser?.user_id)
                  }
                  options={PROJECT_ROLES_OPTIONS}
                  optionBox={({ label }) => label}
                />
              </div>
              <div className="action">
                <button
                  className="remove-btn"
                  onClick={() => removeNewUser(currentNewUser?.user_id)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {!platformAccess && (
                <h4 className="access-error">
                  This user isn&apos;t part of the CreateAI Platform yet. They can
                  join by signing up{" "}
                  <a target="_blank" href={ASU_SIGNUP_URL} rel="noreferrer">
                    here.
                  </a>
                </h4>
              )}
            </div>
          );
        })}
      </div>
      <div className="action-box">
        <div className="copy-actions">
          {data?.webInterface ? (
            <CopyButton text="Copy Viewer Link" link={data?.publicUrl} />
          ) : (
            <Button
              boldText
              type="button"
              className="back-btn"
              onClick={() =>
                toastAlert(TOAST_MESSAGES.ENABLE_PUBLIC_VIEW, ALERT_TYPES.ERROR)
              }
            >
              <FontAwesomeIcon icon={faPaperclip} />
              Copy Viewer Link
            </Button>
          )}
          <CopyButton text="Copy Editor Link" link={href} />
        </div>
        {newUsers.length > 0 && (
          <Button
            boldText
            invertedTheme
            type="submit"
            isLoading={addLoader || editLoader}
            disabled={newUsers.length < 1}
            onClick={inviteHandler}
          >
            {/* <FontAwesomeIcon icon={faPlus} /> */}
            Done
          </Button>
        )}
      </div>
      {!!updateInterface && (
        <div className="disclaimer">
          <p>
            By adding a viewer, your project will be automatically published and
            you can send them the viewer link to access the project
          </p>
        </div>
      )}
    </>
  );
};

export default AccessDetail;
